import 'swiper/css';
import Swal from "sweetalert2";
import Select from 'react-select'
import { BsCheck2 } from "react-icons/bs";
import DatePicker from "react-datepicker";
import { useEffect, useRef, useState } from "react";
import { FaCalendarAlt, FaDownload } from 'react-icons/fa';
import { Swiper, SwiperSlide } from 'swiper/react';
import "react-datepicker/dist/react-datepicker.css";
import MeetMedicineSelection from "./MeetMedicineSelection";
import { Button, Form, Modal, Table, Container, Row, Col, Nav } from "react-bootstrap";
import { CategoriesApi, HealthCareEntityApi, MedicinesApi, VisitDetailCreateView, VisitFeedbackApi, VisitsApi, offset } from "../services/services";
import VisitFeedback from './VisitFeedback';
import PaginationBasic from '../common/Pagination';
import { useNavigate } from "react-router-dom"
import Loader from "../common/Loader";

function PlanMeet() {
    const [loading, setLoading] = useState(false);
    const [entities, setEntities] = useState([]);
    const [meetModal, setMeetModal] = useState(false);
    const [visitModal, setVisitModal] = useState(false);
    const [newMeeting, setNewMeeting] = useState({})
    const [selectedVisit, setSelectedVisit] = useState({})
    const [selectedCats, setSelectedCats] = useState([]);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedMeds, setSelectedMeds] = useState([])
    const [meds, setMeds] = useState([]);
    const [cats, setCats] = useState([]);
    const [activeTab, setActiveTab] = useState()
    const [presentModal, setPresentModal] = useState(false)
    const [shownMeds, setShownMeds] = useState([])
    const [feedbackModal, setFeedbackModal] = useState(false)
    const [feedbackData, setFeedbackData] = useState({
        visitType: '',
        providedSamples: '',
        providedItems: '',
        tasksQuestions: '',
        requestedSamples: '',
        notes: ''
    });
    const [tabCap, setTabCap] = useState([])
    const [filters, setFilters] = useState({
        type: "",
        search: ""
    });
    const types = [
        "doctor",
        "clinic",
        "hospital",
        "chemist"
    ]
    const [temp, setTemp] = useState(0);
    const [activePage, setActivePage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const navigate = useNavigate();
    const [order, setOrder] = useState({});
    const today = new Date();

    const [tableData, setTableData] = useState([]);
    const swiperRef = useRef(null);
    useEffect(() => {
        getList();
        getCategories()
        getMedicineList();
    }, [])

    useEffect(() => {
        getMeetings()
    }, [selectedDate, temp])

    function getCategories() {
        setLoading(true)
        CategoriesApi().then(resp => {
            setActiveTab(resp.data[0]?.id)
            setLoading(false)
            setCats(resp.data)
        }).catch(err => {
            setLoading(false)
            if (err.response.status == 401) {
                Swal.fire("Kindly log in to continue.", "", "warning")
                navigate("/")
            }
        })
    }

    function getList() {
        setLoading(true)
        HealthCareEntityApi("get", `offset=${(activePage - 1) * offset}`).then(resp => {
            setLoading(false)
            setEntities(resp.data.results)
        }).catch(err => {
            setLoading(false)
            if (err.response.status == 401) {
                Swal.fire("Kindly log in to continue.", "", "warning")
                navigate("/")
            }
        })
    }

    function getMeetings() {
        const formattedDate = selectedDate.toISOString().split('T')[0];
        VisitsApi(false, {}, `date=${formattedDate}&offset=${(activePage - 1) * offset}&type=${filters.type}&search=${filters.search}`).then(resp => {
            setTableData(resp.data.results)
            setLoading(false)
            setTotalPage(resp.data.count / offset)
        }).catch(err => {
            setLoading(false)
        })
    }

    function handleAddMeeting(e) {
        e.preventDefault();
        setLoading(true)
        VisitsApi(true, newMeeting).then(resp => {
            Swal.fire("Meeting added successfully.", "", "success")
            setNewMeeting({})
            setLoading(false)
            getList()
            getMeetings()
            setMeetModal(false)
        }).catch(err => {
            setLoading(false)
            Swal.fire("An error occured.", "", "error")
        })
    }

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    function getMedicineList() {
        setLoading(true)
        MedicinesApi().then(resp => {
            setLoading(false)
            setMeds(resp.data)
            setTabCap(resp.data.filter(m => m.category == 2 || m.category == 13).map(m => m.id))
        }).catch(err => {
            setLoading(false)
        })
    }

    const handleFeedbackModalSubmit = (e) => {
        e.preventDefault();
        let tempData = {
            "visit_type": feedbackData.visitType,
            "provided_samples": feedbackData.providedSamples,
            "provided_items": feedbackData.providedItems,
            "tasks_questions": feedbackData.tasksQuestions,
            "requested_samples": feedbackData.requestedSamples,
            "notes": feedbackData.notes
        }
        setLoading(true)
        VisitFeedbackApi(true, tempData).then(resp => {
            setFeedbackModal(false)
            setSelectedCats([])
            setLoading(false)
            Swal.fire("Feedback added successfully.", "", "success")
            getMeetings()
            setFeedbackData({})
        }).catch(err => {
            setLoading(false)
            Swal.fire("An error occured.", "", "error")
        })
    };

    function handleVisitClick(data) {
        let dt = new Date();
        data = { ...data, "visit_time": dt.toISOString() }
        getMedicineList()
        setSelectedVisit(data)
        setVisitModal(true)
    }

    function selectAllMeds() {
        setSelectedCats(cats.map(c => c.id))
        setSelectedMeds(meds.map(m => m.id))
    }

    function dismissVisitModal() {
        Swal.fire({
            text: 'Do you want to cancel this visit?',
            icon: 'warning',
            confirmButtonText: 'Yes',
            showCancelButton: true,
        }).then(result => {
            if (result.isConfirmed) {
                setSelectedVisit({})
                setSelectedCats([])
                setSelectedMeds([])
                setVisitModal(false)
            }
        })
    }

    function showCatalog() {
        if (selectedMeds.length === 0) {
            Swal.fire("Please select medicines for catalog.", "", "warning")
            return;
        }
        setVisitModal(false)
        setPresentModal(true)
    }

    const handleSlideChange = (s) => {
        const activeIndex = swiperRef.current.swiper.realIndex;
        let med_id = selectedMeds[activeIndex]
        let tempMeds = [...shownMeds]
        if (!tempMeds.includes(med_id)) {
            tempMeds.push(med_id)
            setShownMeds(tempMeds)
        }
    };

    function handlePresentModalClose() {
        getMeetings()
        let data = {
            "selected": selectedMeds,
            "shown": shownMeds,
            "visit_time": selectedVisit.visit_time,
            "order": order
        }
        setLoading(true)
        VisitDetailCreateView(selectedVisit.id, data).then(resp => {
            setLoading(false)
            setFeedbackModal(true)
            setPresentModal(false)
            setSelectedCats([])
            setSelectedMeds([])
            setOrder({})
        }).catch(err => {
            setLoading(false)
            if (err.response.data.message) {
                Swal.fire(err.response.data.message, "", "error")
            }
        })
    }

    function handleOrderQuantity(operation, med, type) {
        const currentOrder = order[med.id] || { "box": 0, "strip": 0 };
        let newQuantity;

        if (operation == '+') {
            newQuantity = currentOrder[type] + 1;
        } else if (operation == '-' && currentOrder[type] > 0) {
            newQuantity = currentOrder[type] - 1;
        } else {
            return;
        }

        setOrder(prevOrder => ({
            ...prevOrder,
            [med.id]: { ...currentOrder, [type]: newQuantity }
        }));
    }


    return <div>
        {loading && <Loader />}
        <Container fluid className="daily-call-reporting">
            <Row>
                <div className="col">
                    <h3>Daily Call Reporting</h3>
                </div>
                <div className="col-auto">
                    <button className="btn btn-lp" onClick={() => setMeetModal(true)}>
                        Add Schedule
                    </button>
                </div>
            </Row>
            <Row>
                <Col md={2} sm={6} xs={6}>
                    <Form.Label>Showing record for</Form.Label>
                    <DatePicker
                        maxDate={today}
                        selected={selectedDate}
                        onSelect={handleDateChange}
                        onChange={handleDateChange}
                        dateFormat="dd-MM-YYYY"
                        onChangeRaw={(e) => e.preventDefault()}
                        customInput={
                            <div className="input-container">
                                <input readOnly value={selectedDate ? selectedDate.toLocaleDateString() : ''} className="form-control" />
                                <FaCalendarAlt className="calendar-icon" />
                            </div>
                        }
                    />
                </Col>
                <Col sm={4} md={3}>
                    <Form.Group>
                        <Form.Label>&nbsp;</Form.Label>
                        <Form.Select
                            onChange={(e) => setFilters({ ...filters, type: e.target.value })}
                            name="type"
                        >
                            <option value="">Filter by Type</option>
                            {
                                types.map((val, i) => (
                                    <option value={val} key={i}>{val.toUpperCase()}</option>
                                ))
                            }
                        </Form.Select>
                    </Form.Group>
                </Col>

                <Col sm={5} md={5}>
                    <Form.Group>
                        <Form.Label>&nbsp;</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Search by Name, Area"
                            value={filters.search}
                            required
                            onChange={(e) => setFilters({ ...filters, search: e.target.value })}
                        />
                    </Form.Group>
                </Col>

                <Col className='d-flex align-items-end'>
                    <Button className='px-4' onClick={() => setTemp(temp + 1)}>Search</Button>
                </Col>
            </Row>
            <Row className="mt-4">
                <Col md={12}>
                    <Table striped bordered responsive>
                        <thead>
                            <tr>
                                {/* <th>KYC</th> */}
                                <th>Entity Name</th>
                                <th>Type</th>
                                <th>Specialization</th>
                                <th>Status</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableData.map((data, index) => (
                                <tr key={index}>
                                    {/* <td>{data.kyc}</td> */}
                                    <td>{data.healthcare_entity.name}</td>
                                    <td>{data.healthcare_entity.entity_type.toUpperCase()}</td>
                                    <td>{data.healthcare_entity.speciality}</td>
                                    <td>{
                                        data.completed ? <Button variant="success" size="sm">Completed</Button>
                                            :
                                            selectedDate.toISOString().split('T')[0] == today.toISOString().split('T')[0] ? <Button variant="secondary" size="sm" onClick={() => handleVisitClick(data)}><BsCheck2 /> Visit</Button>
                                                :
                                                <Button variant="danger" size="sm">Missed</Button>
                                    }</td>
                                    <td>
                                        {
                                            data.completed && <a className='btn btn-sm btn-lp' href={`${process.env.REACT_APP_API_LOCAL}generate-pdf/?id=${data.id}`}><FaDownload /></a>
                                        }
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    <PaginationBasic currentPage={activePage} setActive={setActivePage} totalPages={totalPage} />

                </Col>
            </Row>
        </Container>

        <Modal show={visitModal} onHide={() => dismissVisitModal()} backdrop="static" fullscreen>
            <Modal.Header>
                <Modal.Title>
                    <h3>Select Medicies for Visit - {selectedVisit.healthcare_entity?.name}</h3>
                    <hr />
                    <div>
                        <Nav variant="pills" className="sticky-top">
                            {
                                cats && cats.map((c, i) => (
                                    <Nav.Item key={i}>
                                        <Button className="m-1" variant={activeTab == c.id ? "primary" : selectedCats.includes(c.id) ? "outline-primary" : "outline-secondary"} onClick={() => setActiveTab(c.id)}>{c.name}</Button>
                                    </Nav.Item>
                                ))
                            }

                        </Nav>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <MeetMedicineSelection selectedMeds={selectedMeds} setSelectedMeds={setSelectedMeds} meds={meds} cats={cats} activeTab={activeTab} selectedCats={selectedCats} setSelectedCats={setSelectedCats} setActiveTab={setActiveTab} />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" className="me-3" onClick={() => dismissVisitModal()}>
                    Close
                </Button>
                <Button variant="secondary" className="me-3" onClick={() => selectAllMeds()}>
                    Select All
                </Button>
                <Button variant="success" onClick={() => showCatalog()}>
                    Show Catalog
                </Button>
            </Modal.Footer>
        </Modal>

        <Modal show={meetModal} onHide={() => setMeetModal(false)} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>
                    <h3>Add Schedule</h3>
                </Modal.Title>
            </Modal.Header>
            <Form onSubmit={handleAddMeeting}>
                <Modal.Body>
                    <p>This meeting will be added to your whole month schedule.</p>
                    <Form.Label>Health Care Entity</Form.Label>
                    <Select
                        options={entities}
                        getOptionLabel={op => op.name}
                        getOptionValue={op => op.id}
                        onChange={(e) => setNewMeeting({ entity: e.id })}
                        required
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" className="me-3" onClick={() => setMeetModal(false)}>
                        Close
                    </Button>
                    <Button variant="success" type="submit">
                        Add Meeting
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>

        <Modal show={presentModal} onHide={() => setPresentModal(false)} backdrop="static" fullscreen>
            <Modal.Body >
                <Swiper
                    ref={swiperRef}
                    spaceBetween={10}
                    slidesPerView={1}
                    onSlideChange={(s) => handleSlideChange(s)}
                    onSwiper={() => setShownMeds([selectedMeds[0]])}
                    scrollbar={{ draggable: true }}
                    slidesPerGroup={1}
                    breakpoints={{
                        768: {
                            slidesPerView: 1,
                            spaceBetween: 20
                        },
                        1024: {
                            slidesPerView: 1,
                            spaceBetween: 20
                        }
                    }}
                >
                    {
                        meds.filter(m => selectedMeds.includes(m.id)).map(med => (
                            <SwiperSlide key={med.id}>
                                <Row className='d-flex justify-content-center'>
                                    {
                                        tabCap.includes(med.id) ?
                                            <>
                                                <div className='col-md-3 col-sm-4 col-lg-3'>
                                                    <Form.Label>Box Order</Form.Label>
                                                    <div className='d-flex'>
                                                        <Button className='me-3' onClick={(e) => handleOrderQuantity('-', med, 'box')}>-</Button>
                                                        <Form.Control type='number' min={0} value={order[med.id]?.box || 0} onChange={(e) => setOrder((prevOrder) => ({
                                                            ...prevOrder,
                                                            [med.id]: { ...order[med.id], box: parseInt(e.target.value) || 0 }
                                                        }))} />
                                                        <Button className='ms-3' onClick={() => handleOrderQuantity('+', med, 'box')}>+</Button>
                                                    </div>
                                                </div>
                                                <div className='col-md-3 col-sm-4 col-lg-3 ps-3' style={{ borderLeft: "2px solid grey" }}>
                                                    <Form.Label>Strip Order</Form.Label>
                                                    <div className='d-flex'>
                                                        <Button className='me-3' onClick={(e) => handleOrderQuantity('-', med, 'strip')}>-</Button>
                                                        <Form.Control type='number' min={0} value={order[`${med.id}`] ? order[`${med.id}`].strip : 0} onChange={(e) => setOrder((old) => ({ ...old, [`${med.id}`]: { "box": order[`${med.id}`].box, "strip": e.target.value } }))} />
                                                        <Button className='ms-3' onClick={() => handleOrderQuantity('+', med, 'strip')}>+</Button>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <>
                                                <div className='col-md-3 col-sm-4 col-lg-3'>
                                                    <Form.Label>Piece Order</Form.Label>
                                                    <div className='d-flex'>
                                                        <Button className='me-3' onClick={(e) => handleOrderQuantity('-', med, 'box')}>-</Button>
                                                        <Form.Control type='number' min={0} value={order[med.id]?.box || 0} onChange={(e) => setOrder((prevOrder) => ({
                                                            ...prevOrder,
                                                            [med.id]: { ...order[med.id], box: parseInt(e.target.value) || 0 }
                                                        }))} />
                                                        <Button className='ms-3' onClick={() => handleOrderQuantity('+', med, 'box')}>+</Button>
                                                    </div>
                                                </div>
                                            </>
                                    }
                                    <hr className='my-3' />
                                    {/* <div className="col-md-6 offset-1">
                                        <div className="d-flex justify-content-between">
                                            <h4>Name: <b>{med.name}</b></h4>
                                            <h5>MRP: <b>₹{med.mrp}</b></h5>
                                        </div>
                                        {
                                            med.usage ?
                                            <h5>Usage: <b>{med.usage}</b></h5>
                                                :
                                                ""
                                        }
                                    </div> */}
                                    <Col className='d-flex justify-content-center' md={12} sm={12}>
                                        <a href={`${process.env.REACT_APP_API_LOCAL}${med.image}`} target='_blanks'>
                                            <img src={`${process.env.REACT_APP_API_LOCAL}${med.image}`} alt={med.name} style={{ maxHeight: "570px", maxWidth:"1080px"  }} />
                                        </a>
                                    </Col>
                                </Row>
                            </SwiperSlide>
                        ))
                    }
                </Swiper>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => handlePresentModalClose()}>
                    End Presentation
                </Button>
            </Modal.Footer>
        </Modal>

        <Modal show={feedbackModal} backdrop='static'>
            <Modal.Header>
                <Modal.Title>
                    <h3>Visit feedback</h3>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <VisitFeedback feedbackData={feedbackData} setFeedbackData={setFeedbackData} handleFeedbackModalSubmit={handleFeedbackModalSubmit} />
            </Modal.Body>
        </Modal>

    </div>
}

export default PlanMeet