import { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button, Modal, Table } from "react-bootstrap"
import { HealthCareEntityApi, VisitsApi, offset } from "../services/services";
import Swal from "sweetalert2";
import PaginationBasic from '../common/Pagination';
import { useNavigate } from "react-router-dom"
import Loader from "../common/Loader";

function Directory() {
    const [entityModal, setEntityModal] = useState(false);
    const [entities, setEntities] = useState([]);
    const [entityData, setEntityData] = useState({
        entity_type: '',
        name: '',
        location: '',
        phone: '',
    });
    const [activePage, setActivePage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        getList()
    }, [activePage])

    const showEntityModal = () => setEntityModal(true);
    const hideEntityModal = () => setEntityModal(false);

    const types = [
        "doctor",
        "clinic",
        "hospital",
        "chemist"
    ]

    function handleAddEntity(e) {
        e.preventDefault();
        setLoading(true)
        HealthCareEntityApi("create", entityData).then(resp => {
            setLoading(false)
            Swal.fire("Health Care Entity added successfully.", "", "success")
            setEntityData({ entity_type: '', name: '', location: '', phone: '', area: '', speciality: '' });
            hideEntityModal()
            getList()
        }).catch(err => {
            setLoading(false)
            if (err.response.status == 401) {
                Swal.fire("Kindly log in to continue.", "", "warning")
                navigate("/")
            } else {
                Swal.fire("An error occured.", "", "error")
            }
        })
    }

    function getList() {
        setLoading(true)
        HealthCareEntityApi("get", "offset=" + (activePage - 1) * offset).then(resp => {
            setLoading(false)
            setEntities(resp.data.results)
            setTotalPage(resp.data.count / offset)
        }).catch(err => {
            setLoading(false)
            if (err.response.status == 401) {
                Swal.fire("Kindly log in to continue.", "", "warning")
                navigate("/")
            } else {
                Swal.fire("An error occured.", "", "error")
            }
        })
    }

    function addToVisit(entity_id, name) {
            setLoading(true)
        VisitsApi(true, { "entity": entity_id, "today": true }).then(resp => {
            Swal.fire(name + " added to Today's Meeting.", "", "success")
            getList()
        }).catch(err => {
            setLoading(false)
            if (err.response.status == 401) {
                Swal.fire("Kindly log in to continue.", "", "warning")
                navigate("/")
            } else if (err.response.data.message) {
                Swal.fire(err.response.data.message, "", "warning")
            } else {
                Swal.fire("An error occured.", "", "error")
            }
        })
    }

    return <>
        {loading && <Loader />}
        <Container fluid>
            <div className="row">
                <div className="col">
                    <h3>Directory</h3>
                </div>
                <div className="col-auto">
                    <button className="btn btn-lp" onClick={showEntityModal}>
                        Add Entity
                    </button>
                </div>
            </div>

            <Row className="my-3">
                <Col md={12} sm={12}>
                    <Table hover>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Type</th>
                                <th>Speciality</th>
                                <th>Area</th>
                                <th></th>
                            </tr>
                            {
                                entities.map((data, i) => (
                                    <tr key={i}>
                                        <td>{data.name}</td>
                                        <td>{data.entity_type}</td>
                                        <td>{data.speciality}</td>
                                        <td>{data.area}</td>
                                        <td className="d-flex">
                                            <button className="btn btn-lp btn-sm" onClick={() => addToVisit(data.id, data.name)}>
                                                Add to Today's Plan
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            }
                        </thead>
                    </Table>
                    <PaginationBasic currentPage={activePage} setActive={setActivePage} totalPages={totalPage} />

                </Col>
            </Row>
        </Container>

        <Modal show={entityModal} onHide={hideEntityModal}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <h3>Add New Health Care Entity</h3>
                </Modal.Title>
            </Modal.Header>
            <Form onSubmit={handleAddEntity}>
                <Modal.Body>
                    <Form.Group controlId="formUsername">
                        <Form.Label>Entity Type</Form.Label>
                        <Form.Select
                            value={entityData.entity_type}
                            required
                            onChange={(e) => setEntityData({ ...entityData, entity_type: e.target.value })}
                        >
                            <option value="">Select Entity Type</option>
                            {
                                types.map((val, i) => (
                                    <option value={val} key={i}>{val.toUpperCase()}</option>
                                ))
                            }
                        </Form.Select>
                    </Form.Group>
                    <Form.Group controlId="formUsername">
                        <Form.Label>Entity Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter entity name"
                            value={entityData.name}
                            required
                            onChange={(e) => setEntityData({ ...entityData, name: e.target.value })}
                        />
                    </Form.Group>
                    <Form.Group controlId="formUsername">
                        <Form.Label>Phone</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter phone"
                            required
                            value={entityData.phone}
                            onChange={(e) => setEntityData({ ...entityData, phone: e.target.value })}
                        />
                    </Form.Group>
                    <Form.Group controlId="formUsername">
                        <Form.Label>Area</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter Area"
                            required
                            value={entityData.area}
                            onChange={(e) => setEntityData({ ...entityData, area: e.target.value })}
                        />
                    </Form.Group>
                    <Form.Group controlId="formUsername">
                        <Form.Label>Speciality</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter speciality"
                            required
                            value={entityData.speciality}
                            onChange={(e) => setEntityData({ ...entityData, speciality: e.target.value })}
                        />
                    </Form.Group>
                    <Form.Group controlId="formUsername">
                        <Form.Label>Location</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter location"
                            required
                            value={entityData.location}
                            onChange={(e) => setEntityData({ ...entityData, location: e.target.value })}
                        />
                    </Form.Group>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={hideEntityModal}>
                            Close
                        </Button>
                        <Button variant="primary" type="submit">
                            Add Entity
                        </Button>
                    </Modal.Footer>
                </Modal.Body>
            </Form>
        </Modal>
    </>
}

export default Directory