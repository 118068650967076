import axios from "axios";

const offset = 15;

const auth = () => {
    return localStorage.getItem("token");
}

function configheaders() {
    return { headers: { "Authorization": `Token ${auth()}` } }
}

function loginApi(params) {
    let url = `${process.env.REACT_APP_API_LOCAL}login/`;
    return axios.post(url, params, {})
}

function HealthCareEntityApi(action = "get", params = {}) {
    let url = `${process.env.REACT_APP_API_LOCAL}healthcare-entities/`;
    return action == 'create' ? axios.post(url, params, configheaders()) : axios.get(url+`?${params}`, configheaders())
}

function VisitsApi(create = false, params = {}, queryParams = "") {
    let url = `${process.env.REACT_APP_API_LOCAL}mr-visits/?${queryParams}`;
    return create ? axios.post(url, params, configheaders()) : axios.get(url, configheaders())
}

function CategoriesApi() {
    let url = `${process.env.REACT_APP_API_LOCAL}categories/`;
    return axios.get(url, configheaders())
}

function MedicinesApi() {
    let url = `${process.env.REACT_APP_API_LOCAL}medicines/`;
    return axios.get(url, configheaders())
}

function VisitDetailCreateView(visit_id, params={}) {
    let url = `${process.env.REACT_APP_API_LOCAL}mr-visits/${visit_id}/`;
    return axios.post(url, params, configheaders())
}

function DashbaordApi() {
    let url = `${process.env.REACT_APP_API_LOCAL}mr-dashboard/`;
    return axios.get(url, configheaders())
}

function VisitFeedbackApi(create = false, params={}) {
    let url = `${process.env.REACT_APP_API_LOCAL}visit-feedback/`;
    return create ? axios.post(url, params, configheaders()) : axios.get(url, configheaders())
}

export {
    offset,
    loginApi,
    HealthCareEntityApi,
    VisitsApi,
    MedicinesApi,
    CategoriesApi,
    VisitDetailCreateView,
    DashbaordApi,
    VisitFeedbackApi
}