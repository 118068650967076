import React from 'react';
import { Pagination } from 'react-bootstrap';

const PaginationComponent = ({ totalPages, currentPage, setActive }) => {
    const pageItems = [];

    // Function to handle page change
    const handlePageChange = (page) => {
        setActive(page);
    };


    // Create pagination items
    for (let page = 1; page <= totalPages; page++) {
        pageItems.push(
            <Pagination.Item
                key={page}
                active={page === currentPage}
                onClick={() => handlePageChange(page)}
            >
                {page}
            </Pagination.Item>
        );
    }

    return (
        <Pagination>
            <Pagination.Prev
                disabled={currentPage === 1}
                onClick={() => handlePageChange(currentPage - 1)}
            />
            {pageItems}
            <Pagination.Next
                disabled={totalPages < 1}
                onClick={() => handlePageChange(currentPage + 1)}
            />
        </Pagination>
    );
};

export default PaginationComponent;
