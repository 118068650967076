import { useEffect, useState } from "react"
import { Card } from "react-bootstrap"
import { DashbaordApi } from "../services/services"
import { useNavigate } from 'react-router-dom';
import Swal from "sweetalert2";
import Loader from "../common/Loader";

function Home() {
    const [data, setData] = useState({
        today_count: 0,
        completed_count:0,
        yesterday_count: 0
    })
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate()

    useEffect(() => {
            setLoading(true)
        DashbaordApi().then(resp => {
            setLoading(false)
            setData(resp.data)
        }).catch(err => {
            setLoading(false)
            if (err.response.status == 401) {
                Swal.fire("Kindly log in to continue.", "", "warning")
                navigate("/")
            }
        })
    }, [])

    return (
        <div className="row">
        {loading && <Loader />}
            <div class="dashboard-widget-card col-xs-3 col-sm-3 col-md-3">
                <Card>
                    <Card.Body>
                        <h5>Total Visits for Today</h5>
                        <h6>{data.today_count}</h6>
                    </Card.Body>
                </Card>
            </div>
            <div class="dashboard-widget-card col-xs-3 col-sm-3 col-md-3">
                <Card>
                    <Card.Body>
                        <h5>Visits Completed Today</h5>
                        <h6>{data.completed_count}</h6>
                    </Card.Body>
                </Card>
            </div>
            <div class="dashboard-widget-card col-xs-3 col-sm-3 col-md-3">
                <Card>
                    <Card.Body>
                        <h5>Yesterday Missed Visits</h5>
                        <h6>{data.yesterday_count}</h6>
                    </Card.Body>
                </Card>
            </div>
        </div>
    )
}

export default Home