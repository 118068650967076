import { DNA } from 'react-loader-spinner'


export default function Loader() {
    return (
        <div className="full-screen-loader">
            <DNA
                visible={true}
                height="150"
                width="150"
                ariaLabel="dna-loading"
                wrapperStyle={{}}
                wrapperClass="dna-wrapper"
            />
        </div>
    )
}
