import { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";

const VisitType = ({ data, handleChange }) => (
    <Form.Group >
        <Form.Label>What kind of visit was it?</Form.Label>
        <div>
            <Form.Check
                type="radio"
                id="independent"
                name="visitType"
                value="independent"
                checked={data.visitType === "independent"}
                onChange={handleChange}
                label="Independent"
            />
            <Form.Check
                type="radio"
                id="joint"
                name="visitType"
                value="joint"
                checked={data.visitType === "joint"}
                onChange={handleChange}
                label="Joint"
            />
        </div>
    </Form.Group>
);

const ProvidedSamples = ({ data, handleChange }) => {
    return (
        <Form.Group>
            <Form.Label>Did you provide any samples to the doctor?</Form.Label>
            <Form.Control rows={5} as="textarea" name="providedSamples" value={data.providedSamples} onChange={handleChange} />
            <small>Leave blank if not samples provided.</small>
        </Form.Group>
    )
};

const ProvidedItems = ({ data, handleChange }) => (
    <Form.Group >
        <Form.Label>Did you provide any items to the doctor?</Form.Label>
        <Form.Control rows={5} as="textarea" name="providedItems" value={data.providedItems} onChange={handleChange} />
        <small>Leave blank if not items provided.</small>
    </Form.Group>
);

const NewTasks = ({ data, handleChange }) => (
    <Form.Group >
        <Form.Label>Create new tasks, questions and info sought by doctor.</Form.Label>
        <Form.Control rows={5} as="textarea" name="newTasks" value={data.newTasks} onChange={handleChange} />
        <small>Leave blank if not items provided.</small>
    </Form.Group>
);

const SamplesRequested = ({ data, handleChange }) => (
    <Form.Group >
        <Form.Label>Samples requested by doctor</Form.Label>
        <Form.Control rows={5} as="textarea" name="samplesRequested" value={data.samplesRequested} onChange={handleChange} />
        <small>Leave blank if no samples requests.</small>
    </Form.Group>
);

const Notes = ({ data, handleChange }) => (
    <>
        <Form.Group >
            <Form.Label>Anything you would like to take note?</Form.Label>
            <Form.Control as="textarea" rows={7} name="notes" value={data.notes} onChange={handleChange} />
        </Form.Group>
    </>
);

function VisitFeedback({ feedbackData, setFeedbackData, handleFeedbackModalSubmit }) {
    const [activeStep, setActiveStep] = useState(0);

    function handleChange(e) {

        setFeedbackData(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value
        }));
    };

    const steps = [
        { name: 'Visit Type', component: <VisitType data={feedbackData} handleChange={handleChange} /> },
        { name: 'Samples Provided', component: <ProvidedSamples data={feedbackData} handleChange={handleChange} /> },
        { name: 'Items Provided', component: <ProvidedItems data={feedbackData} handleChange={handleChange} /> },
        { name: 'New Tasks', component: <NewTasks data={feedbackData} handleChange={handleChange} /> },
        { name: 'Samples Requested', component: <SamplesRequested data={feedbackData} handleChange={handleChange} /> },
        { name: 'Notes', component: <Notes data={feedbackData} handleChange={handleChange} /> }
    ];

    return (
        <Row>
            <Col sm={12} md={12} xs={12} xl={12}>
                <StepComponent step={steps[activeStep]} />
            </Col>
            <Col className='d-flex justify-content-between mt-3'>
                {activeStep !== 0 &&
                    <Button onClick={() => setActiveStep(activeStep - 1)} className='px-4'>
                        Prev
                    </Button>
                }
                {activeStep === steps.length - 1 ?
                    <Button onClick={handleFeedbackModalSubmit}>Submit</Button>
                    :
                    <Button onClick={() => setActiveStep(activeStep + 1)} className='px-4'>Next</Button>
                }
            </Col>
        </Row>
    );
}

const StepComponent = ({ step }) => (
    <div>
        {step.component}
    </div>
);

export default VisitFeedback;
