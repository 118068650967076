import { Route, Routes } from 'react-router-dom';
import Sidebar from './common/Sidebar';
import Login from './views/Login';
import Home from './views/Home';
import NotFound from './common/NotFound';
import PlanMeet from './views/PlanMeet';
import Performance from './views/Performance';
import Directory from './views/Directory';

function AppRoutes() {
    const withSidebar = (Component) => {
        return <div className="app-container">
            <Sidebar />
            <div className="content">
                <Component />
            </div>
        </div>
    }

    return (
        <Routes>
            <Route path="/" exact element={<Login />} />
            <Route path="/home" exact element={withSidebar(Home)} />
            <Route path="/plan-meet" exact element={withSidebar(PlanMeet)} />
            <Route path="/performance" exact element={withSidebar(Performance)} />
            <Route path="/directory" exact element={withSidebar(Directory)} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    )

}

export default AppRoutes