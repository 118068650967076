import { Col, Row, Nav, Tab, Table, Form, Button } from "react-bootstrap";

function MeetMedicineSelection({ selectedMeds, setSelectedMeds, meds, cats, setActiveTab, activeTab, selectedCats, setSelectedCats, }) {

    const toggleValue = (value, cat_id, single = false) => {
        if (single) {
            const categoryMeds = meds.filter((m) => m.category === cat_id);
            const selectedMedsIds = categoryMeds.map((m) => m.id);
            setSelectedMeds((prevSelectedMeds) => [
                ...new Set([...prevSelectedMeds, ...selectedMedsIds]),
            ]);
            setSelectedCats([...selectedCats, cat_id])
        } else {
            const index = selectedMeds.indexOf(value);
            if (index === -1) {
                setSelectedMeds([...selectedMeds, value]);
                setSelectedCats([...selectedCats, cat_id])
            } else {
                const newArray = selectedMeds.filter(item => item !== value);
                setSelectedMeds(newArray);
                setSelectedCats(oldC => oldC.filter(item => item !== cat_id))
            }
        }
    }

    return <>
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Row>
                {/* <Col md={12} xs={12} sm={12}>
                    <Nav variant="pills">
                        {
                            cats && cats.map((c, i) => (
                                <Nav.Item key={i}>
                                    <Nav.Link active={activeTab == c.id ? true : ""} eventKey={c.id} onClick={() => setActiveTab(c.id)} className={selectedCats.includes(c.id) && activeTab != c.id ? "p-0 mx-1" : "mx-1"}>
                                        {
                                            activeTab == c.id ? c.name
                                                : selectedCats.includes(c.id)
                                                 ?
                                                    <Button variant="outline-primary">
                                                        {c.name}
                                                    </Button>
                                                    : 
                                                    c.name
                                        }
                                    </Nav.Link>
                                </Nav.Item>
                            ))
                        }

                    </Nav>
                </Col> */}
                <Col md={12} xs={12} sm={12}>
                    <Tab.Content>
                        {
                            cats && cats.map((c, i) => (
                                <Tab.Pane active={activeTab == c.id ? true : ""} eventKey={c.id} key={i}>
                                    <Table responsive>
                                        <thead>
                                            <tr>
                                                <th className="d-flex">
                                                    <Form.Check
                                                        checked={selectedMeds.some((m) => meds.find((med) => med.category === c.id && selectedMeds.includes(med.id)))}
                                                        onClick={(e) => toggleValue(e.target.checked, c.id, true)}
                                                    />
                                                    <span className="ms-3">Name</span>
                                                </th>
                                                <th>MRP</th>
                                                <th>Image</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                meds.map(m => (
                                                    m.category == c.id ?
                                                        <>
                                                            <tr key={m.id}>
                                                                <td onClick={() => toggleValue(m.id, c.id)}>
                                                                    <div className="d-flex">
                                                                        <Form.Check checked={selectedMeds.includes(m.id)} />
                                                                        <span className="ms-3">{m.name}</span>
                                                                    </div>
                                                                </td>
                                                                <td>₹ {m.mrp}</td>
                                                                <td>
                                                                    <img onClick={() => toggleValue(m.id)} src={`${process.env.REACT_APP_API_LOCAL}${m.image}`} height={100} />
                                                                </td>
                                                            </tr>
                                                        </>
                                                        : ""
                                                ))
                                            }
                                        </tbody>
                                    </Table>

                                </Tab.Pane>
                            ))
                        }
                    </Tab.Content>
                </Col>
            </Row>
        </Tab.Container>
    </>
}

export default MeetMedicineSelection;