import Swal from "sweetalert2";
import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaHome, FaSignOutAlt, FaCalendarAlt } from 'react-icons/fa';
import { GrDocumentPerformance } from "react-icons/gr";
import { GoFileDirectoryFill } from "react-icons/go";

function Sidebar() {
    const navigate = useNavigate();
    const location = useLocation();

    function logout() {
        Swal.fire({
            text: 'Do you want to logout?',
            icon: 'warning',
            confirmButtonText: 'Yes',
            showCancelButton: true,
        }).then(result => {
            if (result.isConfirmed) {
                localStorage.removeItem("token")
                navigate("/")
            }
        })
    }

    return (
        <nav className="sidebar">
            <div className="logo">
                <img src="/logolp.png" alt="Logo" />
                <span>Levotic Pharma</span>
            </div>
            <p>Hello, { localStorage.getItem('name')}</p>
            <ul className="nav-links">
                <li>
                    <Link to="/home" className={location.pathname.includes("home") ? "active-link" : ""}>
                        <FaHome /> <span>Home</span>
                    </Link>
                </li>
                <li>
                    <Link to="/plan-meet" className={location.pathname.includes("plan-meet") ? "active-link" : ""}>
                        <FaCalendarAlt /> <span>Plan & Meet</span>
                    </Link>
                </li>
                {/* <li>
                    <Link to="/performance" className={location.pathname.includes("performance") ? "active-link" : ""}>
                        <GrDocumentPerformance /> Performance
                    </Link>
                </li> */}
                <li>
                    <Link to="/directory" className={location.pathname.includes("directory") ? "active-link" : ""}>
                        <GoFileDirectoryFill /> <span>Directory</span>
                    </Link>
                </li>
                {/* <li>
                    <Link to="/gsp" className={location.pathname.includes("gsp") ? "active-link" : ""}>
                        <GoFileDirectoryFill /> GSP
                    </Link>
                </li> */}
            </ul>
            <div className="logout-btn">
                <Link to="#" onClick={() => logout()}>
                    <FaSignOutAlt /> Logout
                </Link>
            </div>
        </nav>
    )
}

export default Sidebar